import { Routes, Route } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';

import { Reset } from 'styled-reset';

import GlobalStyle from './styles/GlobalStyle';

import defaultTheme from './styles/defaultTheme';

import HomePage from './pages/HomePage';
import CertificatePage from './pages/CertificatePage';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export default function App() {
  const theme = defaultTheme;

  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <Container>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/certificate" element={<CertificatePage />} />
          </Routes>
        </main>
      </Container>
    </ThemeProvider>
  );
}

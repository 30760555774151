const defaultTheme = {
  fonts: {
    default: "'Spoqa Han Sans Neo', sans-serif",
  },
  colors: {
    background: '#FFFFFF',
    text: '#000',
  },
  sizes: {
    certificateWidth: '59.345rem',
    certificateHeight: '83.944rem',
  },
};

export default defaultTheme;

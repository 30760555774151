/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useState } from 'react';

import styled from 'styled-components';

type Props = {
  opened: boolean;
};

const Container = styled.div<Props>`
  position: absolute;
  top: calc((100% - ${(props) => props.theme.sizes.certificateHeight}) / 2);
  width: ${(props) => props.theme.sizes.certificateWidth};
  height: ${(props) => props.theme.sizes.certificateHeight};
  transform-style: preserve-3d;
  transform: ${(props) => (props.opened ? 'rotateY(-180deg)' : '')};
  transform-origin: left center;
  transition: 2s;
`;

const Front = styled.div`
  position: absolute;
  font-size: 10em;
  width: 100%;
  height: 100%;
  background: #141e35 url(/images/cover-logo.png) no-repeat 50% 30% / 40rem;
  text-align: center;
  backface-visibility: hidden;
  z-index: 1;
`;

const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #141e35;
  box-shadow: 0px 5px 15px #333;
  transform: rotateY(-180deg);
  backface-visibility: hidden;
`;

export default function Cover() {
  const [opened, setOpen] = useState(false);

  const audio = useRef<HTMLAudioElement>(null);

  const clickFront = () => {
    if (audio.current) {
      audio.current.play();
    }
    setOpen(true);
  };

  return (
    <Container opened={opened}>
      <audio ref={audio} id="audio" src="/audios/book.wav" />
      <Back />
      <Front onClick={clickFront} />
    </Container>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

function Root() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

function main() {
  const container = document.getElementById('app');
  if (!container) {
    // TODO: log error
    return;
  }

  const root = ReactDOM.createRoot(container);
  root.render(<Root />);
}

main();

import { Store, Action } from 'usestore-ts';

import Certificate from '../models/Certificate';
import Course from '../models/Course';

import { apiService } from '../services/ApiService';

@Store()
export default class CertificateListStore {
  loading = false;

  certificates: Certificate[] = [];

  async fetchCertificates(course: Course) {
    this.startLoad();
    try {
      const certificates = await apiService.fetchCertificates(course);

      this.completeLoad(certificates);
    } catch (e) {
      this.failLoad();
    }
  }

  @Action()
  private startLoad() {
    this.loading = true;
    this.certificates = [];
  }

  @Action()
  private completeLoad(certificates: Certificate[]) {
    this.loading = false;
    this.certificates = certificates;
  }

  @Action()
  private failLoad() {
    this.loading = false;
    this.certificates = [];
  }
}

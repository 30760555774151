import styled from 'styled-components';

type ContainerProps = {
  imgUrl: string;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  text-align: center;
  background: url(${(props) => props.imgUrl}) no-repeat 0 0 / cover;
  box-shadow: 0px 5px 15px #333;
`;

const Download = styled.a`
  position: fixed;
  bottom: 0;
  right: -10rem;
  aspect-ratio: 1/1;
  width: 6rem;
  height: 6rem;
  background: url(/images/icons/download.svg) no-repeat 0 0 / 6rem 6rem;
  text-indent: -10000px;
  overflow: hidden;
  box-shadow: 3px 3px 5px #CBCBCB;
  border-radius: 2rem;
`;

type ContentsProps = {
  imgUrl: string;
  pdfUrl: string;
}

export default function Contents({ imgUrl, pdfUrl }: ContentsProps) {
  return (
    <Container imgUrl={imgUrl}>
      <Download href={pdfUrl}>다운로드</Download>
    </Container>
  );
}

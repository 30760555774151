/* eslint-disable class-methods-use-this */

import axios from 'axios';

import Certificate from '../models/Certificate';
import Course from '../models/Course';

const id = '18iy1mCMb3zuAwJnNrBdKZg2I3Pbfr8H3Mra4jxNineg';
const key = 'AIzaSyAiKu0RGA7Ws8m6QvmmCKZJ4MlD_eX9K3A';

function getURL(course: Course) {
  return `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${course}?key=${key}`;
}

export default class ApiService {
  async fetchCertificates(course: Course): Promise<Certificate[]> {
    const url = getURL(course);
    const { data } = await axios.get(url);

    const [_, ...rows] = data.values;

    const certificates = rows.map((row: Array<string>) => ({
      id: row[1],
      imageUrl: row[3],
      pdfUrl: row[4],
    }));

    return certificates;
  }
}

export const apiService = new ApiService();

import { useSyncExternalStore } from 'react';
import { STORE_GLUE_PROPERTY_KEY } from './contants';
export default function useStore(store) {
    const glue = Reflect.get(store, STORE_GLUE_PROPERTY_KEY);
    if (!glue) {
        throw new Error('Cannot find store glue');
    }
    const snapshot = useSyncExternalStore(glue.subscribe.bind(glue), glue.getSnapshot.bind(glue));
    return [snapshot, store];
}

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: ${(props) => props.theme.fonts.default};
    font-size: 1.6rem;
    line-height: 1.2;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
  }

  button,
  input,
  textarea,
  select {
    font-family: inherit;
    font-size: inherit;
  }
`;

export default GlobalStyle;

import styled from 'styled-components';

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import Cover from './Cover';
import Contents from './Contents';

import useCertificateListStore from '../../hooks/useCertificateListStore';

const Container = styled.div`
  position: absolute;
  top: calc((100% - ${(props) => props.theme.sizes.certificateHeight}) / 2);
  width: ${(props) => props.theme.sizes.certificateWidth};
  height: ${(props) => props.theme.sizes.certificateHeight};
  perspective: 1800px;
`;

export default function Certificate() {
  const { search } = useLocation();

  const [{ certificates, loading }, store] = useCertificateListStore();

  const { id, course } = queryString.parse(search);

  const certificate = certificates.find((c) => c.id === id);

  useEffect(() => {
    if (id && course) {
      store.fetchCertificates(course as string);
    }
  }, []);

  if (loading) {
    return (<div>Loading...</div>);
  }

  if (!certificate) {
    return (<p>잘못된 접근입니다.</p>);
  }

  return (
    <Container>
      <Contents
        imgUrl={certificate.imageUrl}
        pdfUrl={certificate.pdfUrl}
      />
      <Cover />
    </Container>
  );
}
